import request from '@/utils/request'
import {resultReturn} from "@/api/pklApi";
import store from "@/store";

let timer = store.getters.getResponseTimeout

export function changePwdApi(pwd, conpwd) {
    return new Promise((resolve, reject) => {
        let timeOut = setTimeout(() => {    // 5秒超时
            reject("Server response timed out")
        }, timer)
        request.post("/user/changePassword", {
            oldPwd: pwd,
            newPwd: conpwd
        }).then(res => {
            clearTimeout(timeOut)
            resultReturn(res,resolve, reject)
        }).catch(err => {
            clearTimeout(timeOut)
            reject(err)
        })
    })
}