<template>
  <el-menu style="height: 100%;;"
           :collapse="menuStyle"
           :default-active="item"
           :collapse-transition="false"
           text-color="#A9B1ACFF"
           background-color="#001529ff"
           active-text-color="#ffffff"
           router>
    <!--        菜单栏头部-->
    <div
        style="padding: 0 10px;color: #ffffff;background-color:#00000000;display: flex;align-items: center;justify-content: center;height: 50px;line-height: 40px;text-align: center">
      <img src="../assets/logo.png" style="width: 35px;height: 35px;">
      <h1 v-show="!menuStyle" style="margin-left: 5px;">GcAnyParking</h1>
    </div>
    <div v-for="itme in menus" :key="itme.id">
      <div v-if="itme.path">
        <el-menu-item :index="itme.path" @click="change(itme)">
          <!--            		<i :class="itme.icon" :style="itme.colors"></i>-->
          <i :class="itme.icon" v-if="!(itme.icon ==='el-icon-upload2' || itme.icon==='el-icon-download')"></i>

          <img class="icon_img2" ref="icon_img" v-if="(itme.icon==='el-icon-download')"
               :src="require('@/assets/svg/checkIn5.svg')"/>
          <img class="icon_img" ref="icon_img" v-if="(itme.icon ==='el-icon-upload2')"
               :src="require('@/assets/svg/checkOut5.svg')"/>
          <span slot="title" style="font-size: 13px;">{{ itme.description }}</span>
        </el-menu-item>
      </div>
      <div v-else>
        <el-submenu :index="itme.id+''">
          <template slot="title">
            <i :class="itme.icon">
            </i><span v-show="showLogo">{{ itme.description }}</span>
          </template>
          <div v-for="subitme in itme.children" :key="subitme.id">
            <el-menu-item :index="subitme.path">
              <i :class="subitme.icon"></i>
              <span slot="title">{{ subitme.description }}</span>
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </div>
  </el-menu>
</template>

<script>
import eventBus from "@/js/eventBus";

export default {
  name: "Aside",
  data() {
    return {
      colors: ["color: #ff0000", "color: #00ff00", "color: #0000ff"],
      menus: sessionStorage.getItem("menus") ? JSON.parse(sessionStorage.getItem("menus")) : [],
      item: '',
      menuStyle: false,
      showLogo: true
      // menus:[
      //   {id: 1, name: "HOME", path: "/home", icon: "el-icon-s-home", description: "主页", children: [], pid: null},
      //   {id: 2, name: "停车场管理", path: "/parking", icon: "el-icon-s-order", description: "停车场管理", children: [], pid: null},
      //   {id: 3, name: "设备管理", path: "/device", icon: "el-icon-s-order", description: "设备管理", children: [], pid: null},
      //   {id: 4, name: "入场管理", path: "/putin", icon: "el-icon-s-order", description: "用户管理", children: [], pid: null},
      //   {id: 5, name: "出场管理", path: "/putout", icon: "el-icon-s-order", description: "用户管理", children: [], pid: null},
      //   {id: 6, name: "用户管理", path: "/user", icon: "el-icon-s-order", description: "用户管理", children: [], pid: null},
      //   {id: 7, name: "员工管理", path: "/staff", icon: "el-icon-s-order", description: "员工管理", children: [], pid: null}
      // ]
    }
  },
  mounted() {
    eventBus.$on("menuStyle", data => {
      this.menuStyle = !data
      this.showLogo = data
    })
    eventBus.$on("routerPush", data => {
      this.item = data
    })

  },
  created() {
    if (sessionStorage.getItem("menuStyle") != null) {
      this.menuStyle = JSON.parse(sessionStorage.getItem("menuStyle")).menuStyle
    }

    let path = window.location.href
    const arr = path.split("/"); // 将字符串按照 / 切割成数组
    this.item = '/' + arr[arr.length - 1]; // 取出数组中的最后一个元素
  },
  methods: {
    change(row) {
      eventBus.$emit("menus", row.description)
      this.item = row.path
    }
  }
}
</script>

<style scoped>

.el-menu-item:hover {
  background-color: rgba(202, 202, 203, 0.24) !important;

}

/*选中菜单时颜色*/
.el-menu-item.is-active {
  background-color: rgb(63, 157, 253) !important;
}

.icon_img {
  width: 21px;
  height: 60px;
  margin-right: 10px;
  margin-left: -1px;
  filter: invert(45%);
  color: #fdfdfd;
  transform: rotate(-90deg);
}

.icon_img2 {
  width: 18px;
  height: 60px;
  margin-right: 10px;
  margin-left: 0px;
  filter: invert(45%);
  color: #fdfdfd;
  transform: rotate(-90deg);
}

.el-menu-item.is-active .icon_img {
  filter: invert(0%);
}
.el-menu-item.is-active .icon_img2 {
  filter: invert(0%);
}
.el-menu-item [class^=el-icon-] {
  font-size: 20px;
}
.el-menu-item>span{
  font-size: 14px !important;
}
</style>
