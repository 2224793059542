import request from "@/utils/request";
import store from "@/store";

let timer = store.getters.getResponseTimeout

export function resultReturn(res,resolve, reject) {
    if (res.msg == 'success') {
        return resolve(res)
    } else {
        return reject(res.msg)
    }
}

// export function getUserList_files() {
//     return new Promise((resolve, reject) => {
//         let timeOut = setTimeout(() => {    // 5秒超时
//             reject("User list fetch timeout")
//         }, timer)
//         request.get("/home").then(res => {
//             clearTimeout(timeOut)
//             resultReturn(res,resolve, reject)
//         }).catch(err => {
//             clearTimeout(timeOut)
//             reject(err)
//         })
//     })
// }