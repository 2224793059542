import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 用于控制表格加载动画
        tableLoading: false,
        // 用于控制接口超时时间
        responseTimeout: 25000,
        uploadFlag: false,
    },
    getters: {
        getResponseTimeout(state) {
            return state.responseTimeout
        },
        getUploadFlag(state) {
            return state.uploadFlag
        }
    },
    mutations: {
        SET_TABLE_LOADING: (state, data) => {
            state.tableLoading = data
        },
        SET_UploadFlag(state, data) {
            state.uploadFlag = data
        }
    },
    actions: {
        UpdateTableLoading({commit}, data) {
            commit('SET_TABLE_LOADING', data)
        }
    },
    modules: {}
})