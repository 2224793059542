<template>
  <div style=" font-size: 12px;display: flex;background-color: white;">

    <div
        style="display: flex;justify-content: space-between;align-items: center;width: 100%;height: 60px;min-width: 325px">
      <div style="font-size: 18px;">
        <span :class="btnStyle" style="cursor: pointer; line-height: 60px; font-size: 25px"
              @click="collapseBth"></span>
      </div>

      <div style="width: 100%;">
        <p style="font-size: 18px;margin-left: 10px">{{ routename }}</p>
      </div>


      <div style="margin-right: 0px;display: flex;flex-direction: row;justify-content: flex-end;align-items: center">
        <span style="font-size: 20px;text-align: center;margin-right: 10px;">{{ user.content[0].uname }}</span>
        <el-dropdown style="line-height: 50px;text-align: center;font-size: 26px">
          <i class="el-icon-setting" style="margin-right: 15px;font-size: 22px;"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><span style="text-decoration: none;font-size: 16px;line-height: 30px" @click="personal">Personal</span></el-dropdown-item>
            <el-dropdown-item><span style="text-decoration: none;font-size: 16px;line-height: 30px" @click="loginout">Log out</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>

    </div>

    <!--    //弹窗-->
    <div>
      <el-dialog title="Personal information" :visible.sync="dialogFormVisible" width="450px"
                 v-if="dialogFormVisible"
                 :close-on-click-modal="false">
        <el-form label-width="80px" size="small">
          <!--                  <el-form-item label="ID:" label-width="100px">-->
          <!--                      <el-input v-model="form.uId" disabled autocomplete="off"></el-input>-->
          <!--                  </el-form-item>-->
          <el-form-item label="Name:" label-width="100px">
            <el-input v-model="form.uname" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Rights:" label-width="100px">
            <el-input v-model="form.uRights" disabled autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="Email:" label-width="100px">
            <el-input v-model="form.email" disabled autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Close</el-button>
          <el-button type="primary" @click="update">change Password</el-button>
        </div>
      </el-dialog>

      <!--    修改密碼-->
      <el-dialog title="Edit information" :visible.sync="updateFormVisible" width="450px"
                 v-if="updateFormVisible"
                 :close-on-click-modal="false">
        <el-form label-width="120" size="small" :model="upform" :rules="rules" ref="userForm">

          <!--                  <el-form-item label="User ID:" label-width="155px" style="margin-bottom: 30px;">-->
          <!--                      <el-input v-model="upform.uId" disabled autocomplete="off"></el-input>-->
          <!--                  </el-form-item>-->
          <el-form-item label="Old Password:" label-width="155px" style="margin-bottom: 30px;" prop="pwd">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="upform.pwd" show-password></el-input>
          </el-form-item>
          <el-form-item label="New Password:" label-width="155px" style="margin-bottom: 30px;" prop="newpwd">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="upform.newpwd" show-password></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password:" label-width="155px" style="margin-bottom: 30px;" prop="conpwd">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="upform.conpwd" show-password></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="updateFormVisible = false,upform={}">Cancel</el-button>
          <el-button :loading="changeLoading" type="primary" @click="change">OK</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import eventBus from "@/js/eventBus";
import {changePwdApi} from "@/api/personal";
import md5 from "md5";


export default {
  name: "Header",
  data() {
    return {
      dialogFormVisible: false,
      updateFormVisible: false,
      paths: [],
      rules: {
        pwd: [
          {
            required: true,
            message: 'Please enter the old password or contact the administrator to modify',
            trigger: 'blur'
          },
        ],
        newpwd: [
          {required: true, message: 'Parking name cannot be empty', trigger: 'blur'},
          {min: 6, message: 'Password not less than 6 digits', trigger: 'blur'}
        ],
        conpwd: [
          {required: true, message: 'Confirm password can not be blank', trigger: 'blur'},
          {min: 6, message: 'Password not less than 6 digits', trigger: 'blur'}
        ],

      },
      form: {},
      upform: {},
      routename: "",
      btnStyle: "el-icon-s-fold",
      user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},

      changeLoading: false
    }
  },
  created() {
    eventBus.$on("menus", data => {
      this.routename = data
    })
    if (sessionStorage.getItem("menuStyle")!=null){
      this.btnStyle = JSON.parse(sessionStorage.getItem("menuStyle")).btnStyle
    }
  },

  methods: {
    personal() {
      this.form = this.user.content[0]
      this.dialogFormVisible = !this.dialogFormVisible
    },
    loginout() {
      // localStorage.removeItem("user")
      // localStorage.removeItem("token")
      // localStorage.removeItem("menus")
      // localStorage.removeItem("emp_pkl")
      localStorage.clear()
      sessionStorage.clear()
      this.$router.push("/login")
      this.$message.success("Account has been logged out")
    },
    update() {
      this.upform.uId = this.form.uId
      this.updateFormVisible = !this.updateFormVisible
    },
    change() {
      this.$refs['userForm'].validate((valid) => {//判断表单是否为空和判断，验证两次密码是否一致
        if (valid) {
          if (this.upform.newpwd === this.upform.conpwd) {
            this.changeLoading = true
            let pwd = md5(this.upform.pwd)
            let conpwd = md5(this.upform.conpwd)
            changePwdApi(pwd, conpwd).then(res => {
              this.changeLoading = false
              if (res.msg != 'success') {
                this.$message.warning("Modification failed, please try again or contact the administrator")
              } else {
                this.$message.success(res.msg)
                this.$router.push("/login")
              }
            }).catch(err => {
              this.changeLoading = false
              this.$message.error(err)
            })

          } else {
            this.$message.warning("Inconsistent new passwords entered")
          }
        } else {
          this.$message.warning("Please enter complete information")
        }
      })
    },
    //取消弹窗
    cancel() {
      if (this.editFormVisible) {
        this.editFormVisible = false
      }
      if (this.updateFormVisible) {
        this.updateFormVisible = false
      }
      this.form = {}
      this.upform = {}
    },

    collapseBth() {
      if (this.btnStyle == 'el-icon-s-unfold') {
        this.btnStyle = 'el-icon-s-fold'
        eventBus.$emit("menuStyle", true)
        let menuStyle = {menuStyle: false, btnStyle: 'el-icon-s-fold'}
        sessionStorage.setItem("menuStyle", JSON.stringify(menuStyle))
      } else {
        this.btnStyle = 'el-icon-s-unfold'
        eventBus.$emit("menuStyle", false)
        let menuStyle = {menuStyle: true, btnStyle: 'el-icon-s-unfold'}
        sessionStorage.setItem("menuStyle", JSON.stringify(menuStyle))
      }
    }
  },
  mounted() {
    eventBus.$off("menus")
    eventBus.$on("menus", data => {
      this.routename = data
    })

    let isSmall = false;
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width < 1000 && !isSmall) {
          this.btnStyle = 'el-icon-s-unfold'
          eventBus.$emit("menuStyle", false)
          let menuStyle = {menuStyle: true, btnStyle: 'el-icon-s-unfold'}
          sessionStorage.setItem("menuStyle", JSON.stringify(menuStyle))
          isSmall = true;
        } else if (entry.contentRect.width >= 1000 && isSmall) {
          isSmall = false;
        }
      }
    });
    observer.observe(document.getElementById('app'));
  }
}
</script>

<style scoped>
.el-dropdown-menu--mini .el-dropdown-menu__item {
  line-height: 45px;
}
</style>
