import Vue from 'vue'
import VueRouter from 'vue-router'
import Manage from '../views/Manage.vue'
import {Loading, MessageBox, Message,} from 'element-ui';
import eventBus from "@/js/eventBus";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Manage',
        component: resolve => require(['../views/Manage.vue'], resolve),
        redirect: "/login",
        children: [
            {
                path: '/user',
                name: 'User',
                component: resolve => require(['../views/User.vue'], resolve),
                // component: () => import('../views/User.vue')
            }, {
                path: '/home',
                name: 'Home',
                component: resolve => require(['../views/Home.vue'], resolve),
                // component: () => import('../views/Home.vue')
            }, {
                path: '/parking',
                name: 'Parking',
                component: resolve => require(['../views/Parking.vue'], resolve),
                // component: () => import('../views/Parking.vue')
            }
            , {
                path: '/device',
                name: 'Device',
                component: resolve => require(['../views/Device.vue'], resolve),
                // component: () => import('../views/Device.vue')
            }
            , {
                path: '/putin',
                name: 'Putin',
                component: resolve => require(['../views/Putin.vue'], resolve),
                // component: () => import('../views/Putin.vue')
            }
            , {
                path: '/putout',
                name: 'Putout',
                component: resolve => require(['../views/Putout.vue'], resolve),
                // component: () => import('../views/Putout.vue')
            }
            , {
                path: '/staff',
                name: 'Staff',
                component: resolve => require(['../views/Staff.vue'], resolve),
                // component: () => import('../views/Staff.vue')
            }
        ]
    },
    {
        path: '/login2',
        name: 'Login2',
        component: resolve => require(['../views/Login2.vue'], resolve),
        // component: () => import('../views/Login2.vue')
    },
    {
        path: '/logup',
        name: 'Logup',
        component: resolve => require(['../views/Logup.vue'], resolve),
        // component: () => import('../views/Logup.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: resolve => require(['../views/Login.vue'], resolve),
        // component: () => import('../views/Login.vue')
    },
]


const router = new VueRouter({
    routes
})

let options = {
    lock: true,
    text: 'Loading...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.1)'
}
let loadingInstance = null
let timer = 30000
let timeouter = null

//全局前置路由，配合浏览器sessionStorage进行鉴权操作
router.beforeEach((to, from, next) => {
    loadingInstance = Loading.service(options)
    timeouter = setTimeout(() => {
        loadingInstance.close()
        Message({message: "Service response timeout", type: 'error'})
    }, timer)
    if (to.path === "/login" || to.path === "/logup") {
        next()
    } else {
        let flag = false;
        let menu = JSON.parse(sessionStorage.getItem("menus"))
        if (menu === null) {
            alert("Please verify login first")
            next("/login")
        } else {
            for (var i = 0; i < menu.length; i++) {
                if (to.path === menu[i].path) {
                    flag = true
                    break
                }
            }
            if (flag) {
                next()
            } else {
                alert("Sorry, you do not have this permission.")
            }
        }
    }
})
router.afterEach((to, from) => {
    clearTimeout(timeouter)
    loadingInstance.close();
    eventBus.$emit('routerPush', to.path)
})

export default router
