<template>
  <el-container style="height: 100%;">
    <el-aside width="auto" style="background-color: white; height: 100%;">
      <Aside></Aside>
    </el-aside>

    <el-container>
      <el-header style="height: 60px;background-color: white;box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);">
        <Header :user="user"></Header>
      </el-header>
      <el-main style="padding: 0;">

        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <!--        //显示当前页面子路由-->
<!--        <router-view></router-view>-->
      </el-main>
    </el-container>
  </el-container>
</template>

<style>
/* 淡入淡出过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}



</style>


<script>

import Aside from "@/components/Aside";
import Header from "@/components/Header";

export default {
  data() {
    return {
      collapseBtnClass: 'el-icon-s-fold',
      iscollapse: false,
      aswidth: 200,
      logo: true,
      user: {},
    }
  },
  components: {
    Aside,
    Header
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      let username = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).username : ""
      if (username) {
        // 从后台获取User数据
        this.request.get("/user/username/" + username).then(res => {
          // 重新赋值后台的最新User数据
          this.user = res.data
        })
      }
    },
  }
};
</script>




