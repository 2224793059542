import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import request from "../src/utils/request";
import locale from 'element-ui/lib/locale/lang/en';//设置UI为英文
import VueAMap from 'vue-amap'
import store from './store'

Vue.use(VueAMap)
//初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '84108ac8dd5713ebeafaaab6119776bc',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});

Vue.config.productionTip = false
Vue.prototype.request=request


Vue.use(ElementUI, {locale,size:'mini'});



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
