import axios from 'axios'
import ElementUI from "element-ui";
import store from "@/store";

const request = axios.create({
    baseURL: '/api',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 35000,
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
    //     'Access-Control-Allow-Headers': 'Content-Type'
    // }
    headers: {'Content-Type': 'application/json;charset=utf-8'}
    // headers:{'Content-Type':'application/x-www-form-urlencoded'}
})

let urlLoading = ['/emp/list', '/user/list', '/user/unactivated', '/pklOut/list', '/pklIn/list', '/dev/list', '/dev/unConnectable', '/pkl/list']
// request 拦截器
// 可以自请求发送前对请求做一些处理
request.interceptors.request.use(config => {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';
    //获取vuex的tableLoading
    for (let key in urlLoading) {
        if (config.url === urlLoading[key]) {
            // store.state.tableLoading = true;
            store.dispatch('UpdateTableLoading', true)
        }
    }

    // if (config.url === '/emp/list') {
    //     store.state.tableLoading = true;
    // } else if (config.url === '/user/list' || config.url === '/user/unactivated') {
    //     store.state.tableLoading = true;
    // } else if (config.url === '/pklOut/list') {
    //     store.state.tableLoading = true;
    // } else if (config.url === '/pklIn/list') {
    //     store.state.tableLoading = true;
    // } else if (config.url === '/dev/list' || config.url === '/dev/unConnectable') {
    //     store.state.tableLoading = true;
    // }else if(config.url === '/pkl/list'){
    //     store.state.tableLoading = true;
    // }

    let token = sessionStorage.getItem("token") ? JSON.parse(sessionStorage.getItem("token")) : {}
    if (token) {
        config.headers['token'] = token;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        for (let key in urlLoading) {
            if (response.config.url === urlLoading[key]) {
                // store.state.tableLoading = true;
                store.dispatch('UpdateTableLoading', false)
            }
        }

        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        //用户无权限时提示
        if (res.code === "401") {
            ElementUI.Message({
                message: "Please log in first",
                type: "error"
            })
        }
        return res;
    },
    error => {
        return Promise.reject(error)
    }
)

export default request

